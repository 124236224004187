<template>
  <div class="view">
    <div class="tooltip">
      <div class="iconDiv"></div>
      <img class="log" src="../../../assets/images/pageLogo.png">
      <div style="font-size: 24px;font-weight: 800">智慧菌草云平台</div>
      <Button style="font-size: 18px;cursor: pointer;height: 35px" @click="$router.push('/login')">登入系统</Button>
    </div>
    <div class="content" @click="onclick">
      <div v-for="item in list" class="item"
           :style="{left:item.left,top:item.top,background:item.imgUrl?'rgba(255, 205, 66, 1)':'rgba(18, 162, 93, 1)'}"
           @click="mouseover($event,item)">
        <i class="iconfont icon-xiangmu1" style="pointer-events: none"></i>
      </div>
      <div class="showProject toTop" v-if="selected" ref="show" @click="stopEvent"
           :style="{left:'calc(' +selected.left+' - 30px)',top: 'calc('+selected.top+' + 50px)'}">
        <p :style="{borderBottom: selected.imgUrl? ' 1px solid #e9e9e9':null}">
          <i class="pi pi-map-marker p-mr-2" style="color:rgba(18, 162, 93, 1)"></i>{{ selected.title }}</p>
        <div v-if="selected.imgUrl" class="img">
          <div style="width: 100%;height:100%;overflow: hidden">
            <img alt="" :src="selected.imgUrl"/>
          </div>
        </div>
        <div v-if="selected.contents" class="text">{{ selected.contents }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'map',
  data() {
    return {
      selected: null,
      list: [
        {
          title: '缅甸',
          left: '48%',
          top: '38%',

        },
        {
          title: '老挝',
          left: '51%',
          top: '39%',

        },
        {
          title: '泰国',
          left: '50%',
          top: '43.5%',

        },
        {
          title: '斯里兰卡',
          left: '42%',
          top: '49%',

        },
        {
          title: '厄立特里亚',
          left: '26%',
          top: '43.5%',

        },
        {
          title: '中非',
          left: '18%',
          top: '51.5%',

        },
        {
          title: '卢旺达示范中心',
          left: '23%',
          top: '58%',
          contents: '中国援卢旺达农业技术示范中心于2011年建成。项目共举办培训班57期，培训学员2109人，培养菌草专业留学生5人，为当地菌草产业发展建立' +
              '起核心技术骨干队伍。中心接待来访者16000多名，推广农户3800多户，扶持菌袋生产合作社和企业50多家，涌现了一批青年菌草企业家，带头脱贫致富。' +
              '菌草已经成为当地的新兴产业，受益者超过20,000人，菌草技术和产品从卢旺达辐射到中、东部非洲国家。菌草产业在卢旺达的发展为资源匮乏型国家提供' +
              '了一个可持续发展的样板。\n' +
              'The construction of China-Rwanda Agriculture Technology Demonstration Center (C-RATDC) ' +
              'was completed in 2011. By now, a total of 57 training courses have been offered, 2,109 participants trained, ' +
              '4 foreign students majoring in Juncao technology cultivated, and a team of technical talents for the development' +
              ' of local Juncao industry built. The center received more than 16,000 visitors, instructed more than 3,700 ' +
              'farmers, supported over 50 substrate bag production cooperatives and enterprises, and a group of young Juncao ' +
              'entrepreneurs emerged to take the lead in shaking off poverty and achieving prosperity. Juncao has become ' +
              'a burgeoning local industry with more than 20,000 beneficiaries. Juncao technology and products have spread ' +
              'from Rwanda to Central and Eastern African countries. The development of Juncao industry in Rwanda provides ' +
              'a model of sustainable development for resource-poor countries.',
          imgUrl: require('../../../assets/images/luwangda.jpg'),

        },
        {
          title: '马达加斯加基地',
          left: '29%',
          top: '72%',
          contents: '在安奇贝拉市乡村发展及农业应用研究站建立菌草示范基地。\n' +
              'Juncao Demonstration Base was established at the Rural Development ' +
              'and Agricultural Application Research Station in Anchibeira City.',
          imgUrl: require('../../../assets/images/madajiasijia.jpg'),
        },
        {
          title: '莱索托基地',
          left: '22%',
          top: '82%',
          contents: '中国援莱索托菌草技术项目自2007年9月起实施。已培训学员3234人，并培养菌草专业硕士3名，推广菌草菇农户约1000户，建设菌草技术旗舰点16个，' +
              '种草养畜促进了莱畜牧业的发展，减少当地因过度放牧造成的水土流失问题。项目为当地技术进步、粮食安全和减贫作出积极贡献。\n' + 'China-aid ' +
              'Juncao technology project to Lesotho has been implemented since Sept. 2007, with 3,234 people trained, and 3 ' +
              'students majoring in Juncao technology nurtured in FAFU. Juncao industry has been extended to 1,000 farmers’ ' +
              'households and 16 Juncao technology “Flagship Sites” built. Growing grass and raising livestock has promoted ' +
              'the development of animal husbandry in Lesotho and reduced local soil erosion caused by overgrazing. The project ' +
              'has contributed positively to local technological progress, food security and poverty reduction.',
          imgUrl: require('../../../assets/images/laisuotuo.jpg'),
        },
        {
          title: '南非基地',
          left: '20%',
          top: '84%',
          contents: '南非菌草技术项目自2005年开始实施，建成希德拉菌草技术研究培训中心。中心将技术指导与生产实践相融合，为农村地区失业人员提供了200多个' +
              '固定工作岗位；培训学员507人；辐射多个社区，建设了7个旗舰点、超过40个示范点，1万多户家庭从中受益。当地蘑菇种植从无到有、由少到多，走上了' +
              '千家万户的餐桌，丰富了当地人民营养来源，为消除贫困作出了重要贡献，被誉为"中南合作成功典范" \n' + 'The South African Juncao Technology ' +
              'Project has been implemented since 2005, with the establishment of the Cedara Juncao Technology Research and Training ' +
              'Center. By integrating technical guidance with production practice, the Center has provided more than 200 permanent jobs ' +
              'for unemployed people in rural areas, conducted training to 507 participants, with 7 “Flagship Sites” and more than 40 ' +
              '“Demonstration Sites” set up in many communities, benefiting more than 10,000 farmers’ households. From scratch, the ' +
              'local mushroom cultivation has grown to an emerging industry and mushrooms are now common dishes on the dinner tables ' +
              'of thousands of families, enriching the source of nutrition for the local people, and making an important contribution ' +
              'to the eradication of poverty, which is praised as a “successful model of China-South Africa cooperation”.',
          imgUrl: require('../../../assets/images/nanfei.jpg'),
        },
        {
          title: '朝鲜',
          left: '60%',
          top: '21%',

        },
        {
          title: '巴新基地',
          left: '66.5%',
          top: '61%',
          contents: '2001年，习近平主席在福建工作期间，对援巴布亚新几内亚菌草技术项目作出重要批示，由此开启了菌草援外由技术培训进入项目落地持续性推进新元年。\n'
              +
              'In 2001, when President Xi Jinping was working in Fujian province, he made an important instruction on the ' +
              'Juncao technical assistance project to Papua New Guinea, which started the New Era to Juncao assistance from ' +
              'technical training to the concrete projects implementation, a brand-new mode of benefiting the mankind with ' +
              'Juncao technology. \n' + '2018年11月16日，在习近平主席访问巴新期间，中国政府与巴新政府签订了援巴布亚新几内亚菌草、旱稻技术援助项目换文协议。' +
              '2019年8月项目正式启动，在东高地省建立示范基地。技术已推广到巴新8个省16个地区，举办技术培训班18期，累计培训1,337人，推广8600多农户，30000多民众受益，' +
              '创造了巨菌草产量853吨/公顷的世界记录。东高地省把菌草作为发展经济、实现可持续发展目标的支柱产业。 \n' + 'On Nov. 16, 2018, during H.E. ' +
              'President Xi Jinping’s visit to PNG, the Chinese government and the PNG government signed the Exchange of Letters on ' +
              'Juncao and Dry Land Rice Technical Assistance Project. After the official commencement of the project in Aug. 2019, ' +
              'the demonstration base was established in EHP. The technology has been extended to 16 regions in 8 provinces with 18 ' +
              'technical training courses conducted, 1,337 people trained, and routine technical expertise guidance provided to more ' +
              'than 8,600 households, and benefited over 30,000 people. The Giant Juncao grass harvest created a record-breaking yield ' +
              'of 853 tons per hectare. Juncao technology has been regarded as a pillar industry for economic development and achieving ' +
              'sustainable development goals in EHP.',
          imgUrl: require('../../../assets/images/baxin.jpg'),
        },
        {
          title: '斐济示范中心',
          left: '80%',
          top: '70.5%',
          contents: '中国援斐济菌草技术示范中心是由习近平主席与斐济领导人共同推动的援助项目。项目自2014年实施以来，' +
              '菌草种植面积500余公顷，成功示范海岛菌草循环产业发展模式；培训学员1704名，推广农户1700余户，培育一批专业农户；' +
              '当地菌菇已形成品牌并开始出口；有效缓解当地旱季青饲料匮乏难题，促进畜牧业发展。菌草技术在保护环境同时产出高端农产品，' +
              '被誉为“岛国农业新希望”，为其他岛屿国家提供了可持续发展的样板。\n'
              + 'The China-Fiji Juncao Technology Demonstration ' +
              'Center is jointly initiated by H.E. President Xi Jinping and the Fiji Head of State. Since the project was ' +
              'implemented in 2014, the Juncao planting area has exceeded 500 hectares, and Juncao recycling industry ' +
              'development model on the island has been demonstrated successfully, with a total of 1,704 people trained, ' +
              'the technology extended to over 1,700 farmer households, and a bunch of professionals have been fostered. ' +
              'Local mushrooms have acquired different trademarks and started to be exported to world market. Moreover, ' +
              'Juncao technology effectively alleviated the shortage of green fodder in the dry season and beefed up the ' +
              'animal husbandry development. Not only Juncao technology produced high-end mushrooms, it also performed well ' +
              'in environment protection. It is recognized as the “New Hope for Island Country Agriculture” and provided an ' +
              'excellent model of sustainable development for other island countries.\n' + '已建立8公顷的菌草种植区，作为菌草种苗供应基地。' +
              '已建成2公顷的芒果园套种菌类循环利用示范区，年生产鲜菇300吨的食用菌生产线已建成投产，8个食药用菌品种试验示范成功，攻克了斐济气温高、' +
              '不利菌菇生长-------------------------------、影响菌菇质量的技术难题。\n' +
              'Eight hectares of grass planting area has been' +
              ' established as a grass seedling supply base. Two hectares of mushroom-fruit tree inter-planting production ' +
              'demonstration zone has been established in mango orchard. A production line with capacity of 300 tons of fresh ' +
              'mushrooms per year has been built. Scientific experiments and demonstration on cultivation of 8 varieties ' +
              'mushroom have been successfully conducted. The technical problem of high temperature in Fiji which is ' +
              'unfavorable for the growth of many mushroom species has been solved.',
          imgUrl: require('../../../assets/images/feiji.jpg'),
        },
      ],
    };
  },
  methods: {
    mouseover(event, item) {
      event.stopPropagation();
      this.selected = null;
      this.$nextTick(() => {

        this.selected = item;
        const windowWidth = document.body.clientWidth;
        const windowHeight = document.body.clientHeight;
        this.$nextTick(() => {
          let ref = this.$refs.show;
          if (!item.imgUrl) {
            ref.style.width = '130px';
          }
          if ((ref.offsetTop + 280) > windowHeight) {
            ref.className = 'showProject toBottom';
          }

          if ((ref.offsetTop + 280) > windowHeight && (ref.offsetLeft + ref.clientWidth) > windowWidth) {
            ref.className = 'showProject toRightBottom';
          }
          if ((ref.offsetTop + 280) > windowHeight) {
            ref.style.top = 'calc(' + item.top + ' - 300px)';
          }
          if ((ref.offsetLeft + ref.clientWidth) > windowWidth) {
            ref.style.left = 'calc(' + item.left + ' - 30vw + 60px)';
          }

        });
      });
    },
    stopEvent(event) {
      event.stopPropagation();
    },
    onclick() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
.tooltip {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(
      to right,
      rgba(58, 144, 75, 1),
      rgba(58, 144, 75, 0.01)
  );
  z-index: 1000;
  padding-left: 200px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
  align-items: center;
  left: 0;
  top: 0;
}

.view {
  width: 100%;
  height: 100vh;
}

.tooltip div {
  height: 100%;
  line-height: 50px;
  color: #ffffff;
}

.iconDiv {
  background: #173649;
  width: 130px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
}

.log {
  height: 30px;
  z-index: 200;
  position: absolute;
  left: 25px;
  top: 10px;
}

.iconDiv::after {
  content: "";
  width: 40px;
  height: 75px;
  transform: rotate(30deg);
  background: #173649;
  position: absolute;
  right: -25px;
  top: -25px;
}

.content {
  width: 204.646vh;
  height: 100%;
  background: url("../../../assets/images/backgroud0913.png") no-repeat center center;
  background-size: 100% auto;
  display: flex;
  position: relative;
  overflow: hidden;
}


.item {
  width: 30px;
  height: 30px;
  background-color: rgba(18, 162, 93, 1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  position: absolute;
  left: 20%;
  top: 20%;
  cursor: pointer;
}

.showProject {
  width: 30vw;
  position: absolute;
  left: calc(20% - 30px);
  top: calc(20% + 90px);
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.toRightBottom:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: transparent 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #FFFFFF;
  bottom: -20px;
  right: 35px;
}

.toBottom:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: transparent 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #FFFFFF;
  bottom: -20px;
  left: 35px;
}

.toTop:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: #FFFFFF 10px solid;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  top: -20px;
  left: 35px;
}

.showProject p {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  color: #12A25D;
  padding: 0 10px;
}

.showProject .img {
  max-width: 65%;
  height: 240px;
  padding: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.showProject .img img {
  width: auto;
  height: 100%;
}

.showProject .text {
  flex: 1;
  padding: 5px;
  height: 240px;
  overflow-y: scroll;
  white-space: pre-wrap;
}

.showProject .text::-webkit-scrollbar {
  display: none;
}

</style>